import { z } from "zod";
import { UserContactEditor } from "@/shared/components/UserContactEditor";
import { PartialQuoteSchema, Quote } from "@/shared/types/Quote.interface";
import { PolicyStepSchema } from "./src/schema/PtzCaQuote";
import { FormConfig, FormStep } from "@/shared/types/Form";
import React from "react";
import { SpotLogo } from "@/shared/media/SpotLogo";
import { PolicyEditor } from "@/quote-ptz-ca/src/components/PolicyEditor";
import { CoverageEditor } from "@/shared/components/CoverageEditor";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { AppStateInterface } from "@/shared/contexts/AppLayer";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import { PriceInfo } from "@/shared/components/PriceInfo";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { isAxiosError } from "axios";
import { PtzCaDataUtils } from "./src/utils/PtzCaDataUtils";
import ContactConsentDisclaimer from "@/quote-ptz-ca/src/components/ContactConsentDisclaimer";
import { QueryClient } from "@tanstack/react-query";
import Strings from "@/shared/utils/Strings.constants";
import { PresetCoverageLevel } from "@/shared/utils/CoverageUtils";
import { PublicConfig } from "@/shared/PublicConfig";
import SiteHeader from "@/shared/components/SiteHeader";
import SiteFooter from "@/shared/components/SiteFooter";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { CA_FOOTER_LINKS, SAMPLE_POLICY_URL } from "./src/utils/constants";
import FooterContent from "./src/components/FooterContent";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { TrackingUtils } from "@/shared/utils/TrackingUtils";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import Exclusions from "@/quote-ptz-ca/src/components/Exclusions";
import modalContent from "@/quote-ptz-ca/src/components/FullCoverageDetailsModal";
import { StepperConfig } from "@/shared/components/Stepper";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { EmailUtils } from "@/shared/utils/EmailUtils";

type CaQuoteFormProps = {
    underwriter: PetUnderwriterType;
    updateAppState: (state: Partial<AppStateInterface>) => void;
    updateQuote?: UseFormSetValue<Quote>;
    isUpdating: boolean;
    queryClient: QueryClient;
    setQuoteId: (quoteId: string) => void;
    analyticsUtils: AnalyticsUtils;
    currentStep: CaQuoteFormStepIds;
    updateCurrentStep: (stepId: CaQuoteFormStepIds, quoteId?: string) => void;
    isApplyAllHidden?: boolean;
};

const FORM_ID = PublicConfig.PTZ_CA.FORM_ID;
export type CaQuoteFormStepIds = "pets" | "coverage";

export const getCaQuoteForm = ({
    underwriter,
    updateAppState,
    isUpdating,
    queryClient,
    setQuoteId,
    analyticsUtils,
    isApplyAllHidden,
    currentStep,
    updateCurrentStep,
    updateQuote
}: CaQuoteFormProps): FormConfig<CaQuoteFormStepIds, z.infer<typeof PartialQuoteSchema>> => {
    const quoteApi = new QuoteAPI(underwriter);
    const builderUtils = new BuilderUtils(underwriter);

    const emailUtils = new EmailUtils(underwriter, queryClient);

    const stepsConfig: FormStep<z.infer<typeof PartialQuoteSchema>, CaQuoteFormStepIds>[] = [
        {
            id: "pets",
            stepSchema: PolicyStepSchema,
            getInitialValues: quote => PtzCaDataUtils.getPolicyStepInitialValues(quote),
            title: Strings.ENTER_PET_INFO,
            continueButtonTitle: Strings.PTZ_CA.SELECT_COVERAGE_TEXT,
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const shouldTrackCheckoutStarted = !values?.id;

                const emailDebounceCheck = await emailUtils.handleEmailDebounce({
                    email: values.email,
                    debounce: values?.extra?.debounce,
                    updateAppState
                });

                if (!emailDebounceCheck) return false;

                const segmentIds = analyticsUtils.getSegmentIds(values?.email);
                try {
                    const policyData = QuoteDataUtils.quoteToPetQuote({
                        ...values,
                        lastStepID: "coverage",
                        marketing: {
                            hasEmailConsent: true,
                            hasSMSConsent: true
                        },
                        extra: {
                            ...values.extra,
                            queryParams: analyticsUtils.getQueryParams(values?.extra?.queryParams ?? {})
                        }
                    });

                    const updatedPetQuote = await quoteApi.updateQuote(policyData);

                    if (!updatedPetQuote?.quoteId) {
                        throw new Error("No quote ID in response");
                    }
                    const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter, true);
                    queryClient.setQueryData(["quote", updatedPetQuote.quoteId], updatedQuote);
                    setQuoteId(updatedPetQuote.quoteId);

                    if (shouldTrackCheckoutStarted) {
                        const quoteProperties = TrackingUtils.buildTrackingPayload(updatedPetQuote);
                        analyticsUtils?.trackSegmentEvent(`Checkout Started`, quoteProperties);
                    }
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    return updatedQuote;
                } catch (error) {
                    if (isAxiosError(error)) {
                        const errorStatus = error?.response?.status ?? 0;
                        if (quoteApi.isSpotApiError(error.response?.data)) {
                            const asyncErrors = quoteApi.getErrorIds(error.response?.data);
                            updateAppState({ asyncErrors, isQuoteUpdating: false });
                            return false;
                        }

                        // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                        if (errorStatus >= 400) {
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return false;
                        }
                    }
                    return false;
                }
            },
            render: props => {
                const handleUpdateData = async (dataArray: { type: keyof (typeof BuilderUtils)["typeToFieldKeyMap"]; data: FieldValues }[]) => {
                    await builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: dataArray, updateQuote: updateQuote });
                };
                return (
                    <React.Fragment>
                        <PolicyEditor {...props} />
                        <UserContactEditor
                            config={{
                                showMobilePhone: true,
                                underwriter: "ptz-ca",
                                zipField: {
                                    label: Strings.PTZ_CA.POSTAL_CODE,
                                    placeholder: Strings.PTZ_CA.POSTAL_CODE_PLACEHOLDER,
                                    mask: UIUtils.PostalCodeCAMask,
                                    showGiftCardWrapper: false
                                }
                            }}
                            styles={{
                                wrapper: "grid grid-cols-1 gap-6 lg:grid-cols-3",
                                fields: {
                                    zipCode: "order-1 lg:col-span-1",
                                    email: "order-2 lg:col-span-1",
                                    phone: "order-3 lg:col-span-1"
                                }
                            }}
                        />
                        <ContactConsentDisclaimer className="mt-4 sm:mx-auto sm:max-w-[590px]" />
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId="pets"
                            formData={props.value?.extra?.formData}
                            updateData={handleUpdateData}
                        />
                    </React.Fragment>
                );
            }
        },
        {
            id: "coverage",
            isMaxDeterministicStep: true,
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: true });

                return (
                    <PriceInfo
                        totalPrice={roundedPrice}
                        quoteId={props?.value?.id}
                        policiesCount={props?.value?.policies?.length ?? 0}
                        discountsAmount={discountsAmount}
                        variant="inline"
                    />
                );
            },
            continueButtonTitle: Strings.PROCEED_TO_CHECKOUT,
            disclaimerContent: (
                <div className="mx-auto max-w-[976px] text-xs text-content-secondary">
                    By clicking “Proceed to Checkout” you acknowledge that this policy has specific limitations including an annual coverage limit. The actual policy issued Terms
                    and Conditions will prevail. Medical Conditions that are noted, symptomatic or diagnosed prior to enrollment, or during a waiting period are pre-existing to
                    Coverage and not eligible for reimbursement.
                </div>
            ),
            render: props => {
                const coveragePresetData: PresetCoverageLevel[] = [
                    {
                        name: "Bronze",
                        config: {
                            type: ["accident", "illness"],
                            annualLimit: 5000
                        }
                    },
                    {
                        name: "Silver",
                        config: {
                            type: ["accident", "illness"],
                            annualLimit: 10000,
                            rider: "low"
                        }
                    },
                    {
                        name: "Gold",
                        config: {
                            type: ["accident", "illness"],
                            annualLimit: 10000,
                            rider: "high"
                        }
                    },
                    {
                        name: "Platinum",
                        config: {
                            type: ["accident", "illness"],
                            annualLimit: PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE,
                            rider: "high"
                        }
                    }
                ];

                const handleUpdateData = async (dataArray: { type: keyof (typeof BuilderUtils)["typeToFieldKeyMap"]; data: FieldValues }[]) => {
                    await builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: dataArray, updateQuote: updateQuote });
                };

                return (
                    <>
                        <CoverageEditor
                            {...props}
                            editorConfig={{
                                formId: FORM_ID,
                                coveragePresetData,
                                title: "Create your plan",
                                includeTransactionFee: true,
                                termsInModal: ["annualLimit", "annualDeductible", "reimbursement"],
                                samplePolicyUrl: SAMPLE_POLICY_URL,
                                modalContent: modalContent,
                                preventiveConfig: {
                                    labels: {
                                        basic: "Routine",
                                        advanced: "Advanced"
                                    }
                                },
                                exclusions: <Exclusions />,
                                customizationSlot: (
                                    <CustomizationSlot
                                        type="above-cta"
                                        data={props.value}
                                        formId={FORM_ID}
                                        formStepId="coverage"
                                        formData={props.value?.extra?.formData}
                                        updateData={handleUpdateData}
                                    />
                                )
                            }}
                        />
                    </>
                );
            },
            shouldRerender: () => false,
            hidePreviousSteps: true,
            allowSubmit: () => !isUpdating,
            redirect: async (values, step) => {
                if (!values?.extra?.paymentURL) {
                    throw new Error("No payment URL found");
                }
                if (!values?.id) {
                    throw new Error("No quote ID found");
                }

                updateAppState({ showLoaderDialog: true, isQuoteUpdating: true });
                try {
                    const updatedQuote = await quoteApi.setThankYouUrl(
                        QuoteDataUtils.quoteToPetQuote(values),
                        `${window.location.origin}/forms/${FORM_ID}/thankyou?uw=${underwriter}&quoteId=${values.id}`
                    );

                    const paymentURL = updatedQuote?.extra?.paymentURL;

                    if (!!updatedQuote?.quoteId && !!paymentURL) {
                        window.location.href = paymentURL;
                    } else {
                        console.error("No payment URL found");
                        updateAppState({ showLoaderDialog: false, isQuoteUpdating: false, hasUnknownError: true });
                        return;
                    }
                } catch (error) {
                    console.error(error);
                    updateAppState({ showLoaderDialog: false, isQuoteUpdating: false });

                    if (isAxiosError(error)) {
                        const errorStatus = error?.response?.status ?? 0;

                        // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                        if (errorStatus >= 400) {
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return;
                        }
                    }
                }
            }
        }
    ];

    const steps: StepperConfig<CaQuoteFormStepIds>[] = stepsConfig.map(step => ({
        stepValue: step.id as CaQuoteFormStepIds,
        shouldSkip: step.shouldSkip
    }));

    return {
        id: FORM_ID,
        schema: PartialQuoteSchema,
        header: (value, otherValues, isUpdating) => {
            return (
                <SiteHeader<CaQuoteFormStepIds>
                    formID={FORM_ID}
                    underwriter={underwriter}
                    phoneNumber={Strings.PTZ_CA.PHONE_NUMBER}
                    logo={<SpotLogo />}
                    currentStep={currentStep}
                    steps={steps}
                    updateCurrentStep={updateCurrentStep}
                />
            );
        },
        steps: stepsConfig,
        footer: (value, otherValues, isUpdating) => {
            const policies = value?.policies ?? [];
            const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: value, includeTransactionFee: true });
            return (
                <React.Fragment>
                    <SiteFooter
                        underwriter={underwriter}
                        formID={FORM_ID}
                        links={CA_FOOTER_LINKS}
                        content={<FooterContent />}
                        copyright={Strings.PTZ_CA.COPYRIGHT_TEXT}
                        hasOffset={!!roundedPrice}
                    />
                    <PriceInfo variant="floating" totalPrice={roundedPrice} quoteId={value?.id} policiesCount={policies.length} discountsAmount={discountsAmount} />
                </React.Fragment>
            );
        }
    };
};
