"use client";
// packages
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useDeepCompareMemo } from "@react-hookz/web";

// components
import { Form } from "@/shared/components/Form";
import LoaderWithText from "@/shared/components/LoaderWithText";
import { Container } from "@/shared/components/Container";
import { FormParentContextProvider } from "@/shared/contexts/FormParent";
import { LoaderModal } from "@/shared/components/LoaderModal";
import { QuoteResetModal } from "@/shared/components/QuoteResetModal";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { useQuote } from "@/shared/hooks/useQuote";
import { useOtherValuesMap } from "@/shared/hooks/useOtherValuesMap";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useBreakpoint } from "@/shared/hooks/useBreakpoint";

// utils
import { CaQuoteFormStepIds, getCaQuoteForm } from "./formConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import { StorageUtils } from "@/shared/utils/StorageUtils";
import Strings from "@/shared/utils/Strings.constants";

// types
import { Quote, QuoteSchema } from "@/shared/types/Quote.interface";
import { FormStep } from "@/shared/types/Form";
import { ComponentWithUnderwriter } from "@/shared/types/AppRouter";

// config
import { PublicConfig } from "@/shared/PublicConfig";

const analyticsUtils = new AnalyticsUtils();

const FORM_ID = PublicConfig.PTZ_CA.FORM_ID;
const LOCAL_STORAGE_QUOTE_ID = PublicConfig.PTZ_CA.LOCAL_STORAGE_QUOTE_ID;

export function CaQuoteFormController({ underwriter }: ComponentWithUnderwriter) {
    const submittingRef = useRef(false);
    const initialValuesLoadedRef = useRef(false);
    const [isFormReady, setIsFormReady] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const modal = useModal();
    const { appState, updateAppState } = useAppLayerContext();
    const currentBreakpoint = useBreakpoint();

    // URL hooks
    const searchParams = useSearchParams();
    const router = useRouter();
    const pathname = usePathname();

    // quoteId
    const quoteIdParam = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), `quoteId`);
    const quoteIdInStorage = StorageUtils.getItem(LOCAL_STORAGE_QUOTE_ID);
    const [quoteId, setQuoteId] = useState(() => quoteIdParam ?? quoteIdInStorage ?? ``);

    // Server state
    const { queryClient, quoteQuery, updateQuote, isQuoteUpdating } = useQuote({ quoteId, underwriter, setQuoteId, includeBirthProps: true });
    const memoizedQuoteQuery = useDeepCompareMemo(() => (!!quoteQuery ? (quoteQuery as UseQueryResult<Quote, Error>) : undefined), [quoteQuery]);
    const memoizedUpdateQuote = useDeepCompareMemo(() => (!!updateQuote ? (updateQuote as UseMutationResult<Quote, Error, Quote, unknown>) : undefined), [updateQuote]);

    const { data: quote, isError } = memoizedQuoteQuery || {};

    // Form state
    const form = useForm<Quote>({
        resolver: zodResolver(QuoteSchema),
        defaultValues: { underwriter, extra: { queryParams: searchParams } }
    });

    const {
        setValue,
        getValues,
        reset,
        setError,
        control,
        formState: { errors }
    } = form;
    const formValues = useWatch({ control });

    const { otherValuesMap, updateOtherValues, removeOtherValues } = useOtherValuesMap();

    const stepId = useMemo(() => {
        const foundLastStepID = formValues.lastStepID ?? quote?.lastStepID ?? quote?.extra?.lastStepID;
        const defaultStep = foundLastStepID ?? `pets`;
        const lastStepIdDiff = UIUtils.getTimeDiffNow(quote?.extra?.lastStepIDUpdatedAt as string | undefined, "minutes");

        // If the user has a lastStepID and it's been more than X minutes, send them to the step below
        if (foundLastStepID && lastStepIdDiff > PublicConfig.MAX_RETURN_TO_QUOTE_MINUTES) {
            return "coverage";
        }

        return defaultStep as CaQuoteFormStepIds;
    }, [formValues.lastStepID, quote?.extra?.lastStepID, quote?.lastStepID, quote?.extra?.lastStepIDUpdatedAt]);
    const [currentStep, setCurrentStep] = useState<CaQuoteFormStepIds>(stepId);

    useEffect(() => {
        if (!!stepId) {
            setCurrentStep(stepId);
        }
    }, [stepId]);

    useEffect(() => {
        if (memoizedUpdateQuote?.isError || memoizedQuoteQuery?.isError) {
            setShowResetModal(true);
        }
    }, [memoizedUpdateQuote?.isError, memoizedQuoteQuery?.isError]);

    useEffect(() => {
        updateAppState({ currentStepID: currentStep });
    }, [updateAppState, currentStep]);

    // Methods

    const resetQuote = useCallback(() => {
        queryClient.clear();
        setShowResetModal(false);
        setQuoteId(``);
        setCurrentStep(`pets`);
        StorageUtils.removeItem(LOCAL_STORAGE_QUOTE_ID);
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete("quoteId");
        const newUrl = `${pathname}?${newSearchParams.toString()}`;
        reset({ underwriter });
        router.push(newUrl);
    }, [pathname, queryClient, reset, router, searchParams, underwriter]);

    const updateCurrentStep = useCallback(
        (stepId: CaQuoteFormStepIds, quoteId?: string) => {
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _quoteId = quoteId;

            if (!!_quoteId && !_quoteIdInParams) {
                params.set(`quoteId`, _quoteId);
            }

            const newUrl = `${PublicConfig.BASE_PATH}${pathname}?${params.toString()}`;
            window.history.pushState({ currentStep: stepId }, "", newUrl);

            setCurrentStep(stepId);

            if (!!appState.asyncErrors?.length) {
                updateAppState({ asyncErrors: undefined });
            }
        },
        [appState.asyncErrors?.length, pathname, searchParams, updateAppState]
    );

    const handleStepChange = useCallback(
        async (newStepIndex: number, newStep: FormStep<Quote, CaQuoteFormStepIds, keyof Quote>, value?: Quote) => {
            if (!newStep?.id) return;

            updateCurrentStep(newStep.id, value?.id);
        },
        [updateCurrentStep]
    );

    // Form
    const { isApplyAllHidden } = appState;
    const quoteForm = useMemo(
        () =>
            getCaQuoteForm({
                underwriter,
                updateQuote: setValue,
                updateAppState,
                isUpdating: !!appState?.isQuoteUpdating,
                queryClient,
                setQuoteId,
                analyticsUtils,
                isApplyAllHidden,
                currentStep,
                updateCurrentStep
            }),
        [appState?.isQuoteUpdating, currentStep, isApplyAllHidden, queryClient, setValue, underwriter, updateAppState, updateCurrentStep]
    );

    const { steps } = quoteForm;

    const checkStepsRunningRef = useRef(false);

    // This effect runs when a quote does not have a lastStepID value.  It validates the current quote data against the stepSchema and allowContinue
    // methods of each step until it finds a step that is not valid.  It then sets the lastStepID to the id of the invalid step and calls handleStepChange
    useEffect(() => {
        const checkSteps = async () => {
            try {
                for (const [index, stepEntry] of steps.entries()) {
                    const { stepSchema, id, shouldSkip, allowContinue, isMaxDeterministicStep } = stepEntry;

                    if (shouldSkip) {
                        continue;
                    }

                    if (isMaxDeterministicStep) {
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    const isValid = stepSchema?.safeParse(quote);

                    if (!!stepSchema && !isValid?.success) {
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    if (!!allowContinue) {
                        const canContinue = await allowContinue(quote, otherValuesMap[id]);
                        if (canContinue) {
                            continue;
                        }
                    }

                    setValue(`lastStepID`, id);
                    handleStepChange(index, stepEntry, quote);
                    break;
                }
            } catch (error) {
                console.error("Error in checkSteps:", error);
            } finally {
                checkStepsRunningRef.current = false;
            }
        };

        if (!!quote?.id && !formValues.lastStepID && !!steps.length && !checkStepsRunningRef.current && isFormReady) {
            checkStepsRunningRef.current = true;
            checkSteps();
        }
    }, [formValues.lastStepID, handleStepChange, isFormReady, otherValuesMap, quote, setValue, steps]);

    const handleSubmitEffects = async (value: Quote, step: FormStep<Quote, CaQuoteFormStepIds, keyof Quote>): Promise<string | void> => {
        /* For any side effects we need to run on submitting a step */
        if (step.allowSubmit && step.allowSubmit(value, otherValuesMap[step.id])) {
        }
    };

    // Effects

    useEffect(() => {
        const handlePopState = async (event: PopStateEvent) => {
            const stateStepId = window.history.state?.currentStep;
            if (stateStepId) {
                setCurrentStep(stateStepId as CaQuoteFormStepIds);
            } else {
                setCurrentStep(`pets`);
            }
        };

        // Add the async event listener
        window.addEventListener("popstate", handlePopState);

        // Clean up the event listener
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);

    useEffect(() => {
        // redirect to /thankyou if the quoteStatus is "finalized"

        if (quote?.quoteStatus === `finalized` && !submittingRef.current) {
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _underwriter = UIUtils.getCaseInsensitiveValue(params, "uw");
            const _quoteId = quote?.id;

            if (!!_quoteId && !_quoteIdInParams) {
                params.set(`quoteId`, _quoteId);
            }

            if (!_underwriter) {
                params.set(`uw`, underwriter);
            }

            StorageUtils.removeItem(LOCAL_STORAGE_QUOTE_ID);

            const newUrl = `${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou?${params.toString()}`;
            router.push(newUrl, { scroll: false });
        }
    }, [quote?.id, quote?.quoteStatus, resetQuote, router, searchParams, underwriter]);

    useEffect(() => {
        // Push underwriter value into AppLayer for use in other components
        if (!!underwriter) {
            updateAppState({ underwriter });
        }
    }, [underwriter, updateAppState]);

    useEffect(() => {
        if (!quoteId && !!quoteIdInStorage && !quoteIdParam && !submittingRef.current) {
            setQuoteId(quoteIdInStorage);
        }
        // Add to local storage if not set
        if (!!quote?.id && !quoteIdInStorage && !!quote?.quoteStatus && quote.quoteStatus !== `finalized`) {
            StorageUtils.setItem(LOCAL_STORAGE_QUOTE_ID, quote.id);
        }
    }, [quote?.id, quote?.quoteStatus, quoteId, quoteIdParam, quoteIdInStorage]);

    useEffect(() => {
        // If the browser has a pCode and a quote ID exists, we should update the quote with it.
        const browserPCode = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "pcode");
        const allValues = getValues();
        if (!!browserPCode && allValues?.discountCode !== browserPCode && initialValuesLoadedRef.current) {
            const newPCode = Array.isArray(browserPCode) ? browserPCode[0] : browserPCode;
            setValue(`discountCode`, newPCode);
            setValue(`affiliateCode`, newPCode);
            if (!!quote?.id && quote.discountCode !== newPCode) {
                memoizedUpdateQuote?.mutate({ ...allValues, discountCode: newPCode, affiliateCode: newPCode });
            }
        }
    }, [getValues, searchParams, quote?.discountCode, quote?.id, setValue, memoizedUpdateQuote]);

    useEffect(() => {
        // Sets initial form values once the quote ID is available
        if (!initialValuesLoadedRef.current && !quoteIdParam && !quoteIdInStorage) {
            initialValuesLoadedRef.current = true;
            setIsFormReady(true);
        }

        if (!initialValuesLoadedRef.current && quoteId && !!quote?.id) {
            initialValuesLoadedRef.current = true;
            const allValues = getValues();
            const merged = { ...quote, ...allValues, underwriter, extra: { ...quote.extra } };
            reset(merged);
            setIsFormReady(true);
        }
    }, [quoteId, quote, reset, getValues, quoteIdParam, quoteIdInStorage, formValues.id, underwriter]);

    useEffect(() => {
        // Here we update the form values from the API data
        if (!!quote?.id) {
            reset(quote);
            if (PublicConfig.ENVIRONMENT === `development`) {
                StorageUtils.setItem(`dev-quote`, JSON.stringify(quote));
            }
        }
    }, [quote, reset]);

    useEffect(() => {
        if (!!memoizedQuoteQuery) {
            updateAppState({ quoteQuery: memoizedQuoteQuery });
        }
    }, [memoizedQuoteQuery, updateAppState]);

    useEffect(() => {
        if (!!memoizedUpdateQuote) {
            updateAppState({ updateQuote: memoizedUpdateQuote });
        }
    }, [memoizedUpdateQuote, updateAppState]);

    useEffect(() => {
        updateAppState({ isQuoteUpdating: isQuoteUpdating });
    }, [isQuoteUpdating, updateAppState]);

    useEffect(() => {
        updateAppState({ breakpoint: currentBreakpoint });
    }, [currentBreakpoint, updateAppState]);

    // const quoteIdInStateNotSet = !!quote?.id && !quoteId;
    // const quoteInParamsNotLoaded = !!quoteIdParam && !quote?.id;
    // const formStepIdNotSet = !isFormReady && !formValues.lastStepID;
    const shouldShowLoader = false;

    return (
        <>
            {appState.showLoaderDialog && <LoaderModal />}
            <QuoteResetModal onReset={resetQuote} isVisible={showResetModal} />
            <FormParentContextProvider methods={form}>
                {shouldShowLoader ? (
                    <div className="relative flex min-h-screen items-center justify-center">
                        <Container>
                            <LoaderWithText text={!quoteId && !quoteIdParam ? "Loading..." : "Picking up where you left off..."} />
                        </Container>
                    </div>
                ) : (
                    <Form<CaQuoteFormStepIds, Quote>
                        key={currentStep}
                        form={quoteForm}
                        initialStepID={currentStep}
                        value={formValues}
                        otherValuesMap={otherValuesMap}
                        isUpdating={appState?.isQuoteUpdating}
                        onStepChange={handleStepChange}
                        onSubmitStep={handleSubmitEffects}
                        uwConfig={{ phone: Strings.PTZ_CA.PHONE_NUMBER, hours: UIUtils.getPhoneHours("PTZ_CA") }}
                    />
                )}
            </FormParentContextProvider>
            {modal.render}
        </>
    );
}
