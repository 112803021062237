// packages
import * as z from "zod";
import { useFormContext, Controller } from "react-hook-form";

// components
import { Checkbox, Label } from "@/shared/components/ui";
import { FormField } from "@/shared/components/FormField";
import { FormHint } from "@/shared/components/FormHint";
import Link from "next/link";

// utils
import { cn } from "@/shared/utils";

// types
import { PolicyStepSchema } from "@/quote-ptz-ca/src/schema/PtzCaQuote";
import Strings from "@/shared/utils/Strings.constants";

type AllPolicyStepProps = z.infer<typeof PolicyStepSchema>;

const ContactConsentDisclaimer = (props: { className?: string }) => {
    const { className } = props;
    const {
        control,
        setValue,
        formState: { errors }
    } = useFormContext<AllPolicyStepProps>();

    return (
        <div className={cn("text-xs text-content-secondary", className)}>
            <Controller
                name="extra.policyStepConsented"
                control={control}
                render={({ field: { ref, value } }) => (
                    <>
                        <FormField className="mb-6 flex-row space-x-2">
                            <Checkbox
                                id="contact-consent"
                                aria-labelledby="contact-consent-label"
                                checked={value}
                                ref={ref}
                                error={errors?.extra?.policyStepConsented?.message}
                                onCheckedChange={(value: boolean) => setValue("extra.policyStepConsented", value, { shouldValidate: true })}
                            />
                            <Label
                                id="contact-consent-label"
                                htmlFor="contact-consent"
                                className="mb-0 flex flex-col gap-6 bg-background-transparent text-xs font-normal text-content-secondary"
                            >
                                {" "}
                                By clicking &quot;{Strings.PTZ_CA.SELECT_COVERAGE_TEXT}&quot; you agree that Spot Pet Insurance and its affiliates may communicate with you for
                                marketing purposes via email, text, or phone through the email and/or phone numbers provided to Spot. You are not required to provide your phone
                                number to Spot Pet Insurance. Your consent to these terms is not a condition of being able to purchase pet insurance. Your information is collected
                                and used in accordance with Spot Pet Insurance Privacy Policy. Text messages are subject to the Spot Pet Insurance Texting Terms. Opt out any time.{" "}
                            </Label>
                        </FormField>
                        <div className="ml-6">
                            <p>
                                Please see{" "}
                                <Link href="https://spotpetinsurance.ca/privacy" target="_blank" className="underline">
                                    Privacy Policy
                                </Link>{" "}
                                for more information.{" "}
                            </p>
                            {errors?.extra?.policyStepConsented && <FormHint variant="error">{errors?.extra.policyStepConsented?.message}</FormHint>}
                        </div>
                    </>
                )}
            />
        </div>
    );
};

export default ContactConsentDisclaimer;
