import { z } from "zod";
import { Quote } from "@/shared/types/Quote.interface";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { PartialPolicyStepSchema } from "../schema/PtzCaQuote";

export class PtzCaDataUtils {
    static getPolicyStepInitialValues(quote?: Quote): z.infer<typeof PartialPolicyStepSchema> {
        // Destructure existing values from quote
        const { email, ratingZipcode, phone, policies, extra, id } = quote || {};

        // Use existing values if available, otherwise fall back to defaults
        const initialValues = {
            email: email || "",
            ratingZipcode: ratingZipcode || "",
            phone: phone || "",
            policies: !!id && policies?.length ? policies : [QuoteDataUtils.createNewPolicy()],
            extra: extra || {}
        };

        return initialValues;
    }
}
